import Typography from "typography"
import gray from "gray-percentage"
import {
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from "typography-breakpoint-constants"

const theme = {
  title: "mayask",
  baseFontSize: "18px",
  baseLineHeight: 1.58,
  googleFonts: [
    {
      name: "Source Sans Pro",
      styles: ['300', '400', "700"],
    },
  ],
  headerFontFamily: ["Source Sans Pro", "Helvetica Neue", 'Helvetica', 'Arial', 'sans-serif'],
  bodyFontFamily: ["Source Sans Pro", "Helvetica Neue", 'Helvetica', 'Arial', 'sans-serif'],
  bodyColor: "hsla(0,0%,0%,0.9)",
  headerWeight: 700,
  bodyWeight: 400,
  boldWeight: 700,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    a: {
      color: '#000',
      // textDecoration: "none",
    },
    "a:hover,a:active": {
      // textDecoration: "underline",
    },
    blockquote: {
      ...scale(1 / 9),
      color: gray(31),
      fontStyle: "italic",
      paddingLeft: rhythm(13 / 16),
      marginLeft: 0,
      borderLeft: `${rhythm(3 / 16)} solid ${gray(30)}`,
    },
    "blockquote > :last-child": {
      marginBottom: 0,
    },
    "blockquote cite": {
      ...adjustFontSizeTo(options.baseFontSize),
      color: options.bodyColor,
      fontWeight: options.bodyWeight,
    },
    "blockquote cite:before": {
      content: '"— "',
    },
    ul: {
      listStyle: "disc",
    },
    "ul,ol": {
      marginLeft: '1.1rem',
    },
    [MOBILE_MEDIA_QUERY]: {
      "ul,ol": {
        marginLeft: rhythm(1),
      },
      blockquote: {
        marginLeft: rhythm(-3 / 4),
        marginRight: 0,
        paddingLeft: rhythm(9 / 16),
      },
    },
    [TABLET_MEDIA_QUERY]: {
      h1: {
        ...scale(5 / 5),
      },
    },
    "h1,h2,h3,h4,h5,h6": {
      marginTop: rhythm(2),
    },
    h1: {
      ...scale(6 / 5),
      letterSpacing: "-2px",
    },
    h6: {
      fontStyle: "italic",
    },
  }),
}

const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
